* {
  margin: 0;
  padding: 0;
}

$width: 6px;
$n: 60;
$t: 3s;
$z: -20px;
$gap: 0px;
$c1: #ffc400;
$c2: #1cbeaf;

.dna {
  width: ($width + $gap * 2) * $n;
  perspective: 270px;
  transform-style: preserve-3d;
  letter-spacing: -5px;
  margin-top: 30px;
  &:after {
    letter-spacing: 5px;
    display: block;
    padding-top: 50px;
    color: #aaa;
    text-align: center;
  }
}

.dna_container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.child {
  position: relative;
}

.ele {
  width: $width;
  height: $width;
  display: inline-block;
  animation: rotate $t linear infinite;
  transform: rotateX(-360deg) translateZ(0);
  transform-style: preserve-3d;
  margin-left: $gap;
  &:nth-of-type(2n) .dot:before {
    background: $c2;
  }
}

.dot {
  width: inherit;
  height: inherit;
  transform: translateZ($z);
  transform-style: preserve-3d;
  &:before {
    content: "";
    width: inherit;
    height: inherit;
    display: block;
    background: $c1;
    border-radius: 50%;
    animation: rotate $t linear infinite reverse;
    transform: rotateX(-360deg) translateZ(0);
  }
}

@for $i from 1 through $n {
  $delay: $t / $n * $i * -2;
  .ele:nth-of-type(#{$i}),
  .ele:nth-of-type(#{$i}) .dot:before {
    animation-delay: $delay;
  }
  .ele:nth-of-type(#{$i}):nth-of-type(odd) {
    animation-delay: $delay - ($t * 0.5);
  }
}

@keyframes rotate {
  to {
    transform: none;
  }
}
